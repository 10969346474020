.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#header {
  margin: 0 0 24px;
  position: relative;
}

.App-name {
  color: white;
  text-align: center;
  font-family: 'Dancing Script', cursive;
  font-size: 80px;
  font-weight:700;
  margin-bottom: 50px;
}

#intro {
  padding: 10px;
}

/*------------------------------------*\
  Site box
\*------------------------------------*/

#contain #content2 {
  position: relative;
  padding: 20px;
}

/*------------------------------------*\
  Modal
\*------------------------------------*/
.Modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: papayawhip;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rebeccapurple;
}