/*------------------------------------*\
  CSS Reset
\*------------------------------------*/

* {
  margin: 0;
  padding: 0;
  font-style: inherit;
  cursor: default;
  outline: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box; }

body, html {
  width: 100%;
  height: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight:300;
  color: #fff; /* Font Color Color Here */
}

/* Background pattern from subtlepatterns.com */
body {
  background-color: #191919;
  background-image: url('./img/crissXcross.png');
}

@media all and (-webkit-min-device-pixel-ratio: 1.5) {
  body {
      background-image: url('./img/crissXcross_@2x.png');
      background-size: 512px 512px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*------------------------------------*\
  Skills
\*------------------------------------*/

#intro {
  font-size: 22px;
  color: #fff;
  line-height: 30px;
  text-align: center;
  margin-bottom: 24px;
  display: block;
}

#intro a {
  color: #fff;
}

/*------------------------------------*\
  Networks
\*------------------------------------*/

#networks {
  padding-top: 24px;
  text-align: center;
}

#networks a {
  color: white;
  text-align: center;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 30px;
  opacity: 0.3;
  font-family: 'icomoon';
  text-decoration: none;
  cursor: pointer;
}

#networks a:first-child {
  margin-left: 0px;
}

#networks a:hover {
  color: #fff;
  opacity: .9;
  cursor: pointer;
}

/*------------------------------------*\
  Clearfix
\*------------------------------------*/

.clear, #header, #footer {
  zoom: 1; }

.clear:before, #header:before, #footer:before, .clear:after, #header:after, #footer:after {
  content: '';
  display: block; }

.clear:after, #header:after, #footer:after {
  clear: both; }